
.modal-backdrop {
    z-index: 1050;
}
.container-layout {
    z-index: 1050;
}

/*Overwritting Bootstrap Modal styles*/
.modal {
  top: 40px!important;
}

@media (min-width: 768px) {
    .pioneer-modal-lg {
      max-width: 700px;
    }
    .pioneer-modal-xl,
    .pioneer-modal-xxl {
      max-width: 768px;
    }
  }
  
  @media (min-width: 992px) {
    .pioneer-modal-lg {
      max-width: 900px;
    }
    .pioneer-modal-xl,
    .pioneer-modal-xxl
     {
      max-width: 992px;
    }
  }
  
  @media (min-width: 1200px) {
    .pioneer-modal-lg {
      max-width: 1000px;
    }
    .pioneer-modal-xl,
    .pioneer-modal-xxl
     {
      max-width: 1100px;
    }    
  
  }
  
  @media (min-width: 1440px) {
    .pioneer-modal-xxl {
      max-width: 1300px;
    }  
  }