
/* You can add global styles to this file, and also import other style files */
@use "./assets/styles/utils.scss";
@use "./assets/styles/modals.scss";
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import '~@angular/material/theming';
@include mat-core();

$reference-master-web-primary: mat-palette($mat-indigo);
$reference-master-web-accent: mat-palette($mat-pink, A200, A100, A400);
$reference-master-web-warn: mat-palette($mat-red);
$reference-master-web-theme: mat-light-theme((
  color: (
    primary: $reference-master-web-primary,
    accent: $reference-master-web-accent,
    warn: $reference-master-web-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($reference-master-web-theme);

.pending-match-list--entity-title {
    margin-left: 4.625rem;
 }