a {
    cursor: pointer;
}

label {
    display: block;
}

.app-select-component {
    width: fit-content;
}

.d-inline-block {
    display: inline-block;
}

.dflex-inline-block {
    display: inline-flex;
}

.mxw-200 {
    max-width: 200px;
}
.mxw-50 {
    min-width: 50px;
}

.h-100 {
    height: 100%;
}

.h-auto {
    height: auto;
}
  
.c-pointer {
    cursor: pointer;
}
  
.c-notallowed {
    cursor: not-allowed!important;
}

.record--detail {
    background-color: rgba(238, 239, 240, 0.5);
}

.show-more--button {
    color: #336eb4;
    text-decoration: none;
    background-color: transparent;
}

.row-selected {
    background-color: #eaeff3;
}

.bg-ods-charcolar {
    background-color: #ededed;
}

.color-error {
    color: #dc2f30;
}


.entity-item-option {
    border: 1.5px solid rgb(216, 214, 214);
    border-radius: 15px;
    height: fit-content;
    &.selected {
        border: 2.5px solid rgba(51, 110, 180, 1);
     }
}

.entity-item-option--show-more {
    background-color: rgba(238, 239, 240, 0.5);
    &:hover {
        cursor: pointer;
    }
}

.entity-item-option--show-more-container {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.stepper-button--left {
    border-radius: 5px 0 0 5px;
}

.stepper-button--rigth {
    border-radius: 0 5px 5px 0;
}

.row-status-header:has(> select) {
    min-width: 150px;
}
.row-status-header select {
    padding: 5px;
}